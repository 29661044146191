<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate"
        >新增</el-button
      >
      <el-input
        v-model="listQuery.name"
        placeholder="请输入赛事名称"
        style="width: 150px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.is_on"
        placeholder="状态"
        clearable
        style="width: 150px; margin-right: 50px"
        class="filter-item"
      >
        <el-option label="正常" :value="1" />
        <el-option label="已下架" :value="2" />
      </el-select>
      比赛时间：
      <el-date-picker
        v-model="dateArr"
        type="daterange"
        align="right"
        value-format="yyyy-MM-dd"
        unlink-panels
        range-separator="至"
        start-placeholder="比赛开始日期"
        end-placeholder="比赛结束日期"
        class="filter-item"
        style="display: inline-flex"
      ></el-date-picker>

      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="ID" width="95" align="center">
        <template slot-scope="scope">
          {{  scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="赛事名称" min-width="110" align="center">
        <template slot-scope="scope"> {{ scope.row.name }}</template>
      </el-table-column>
      <el-table-column label="赛事类型" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.match_type.name }} </template>
      </el-table-column>
      <el-table-column label="报名开始时间" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.enroll_start_at }} </template>
      </el-table-column>
      <el-table-column label="报名结束时间" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.enroll_end_at }} </template>
      </el-table-column>
      <el-table-column label="举办地点" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.venue }} </template>
      </el-table-column>
      <el-table-column label="举办单位" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.organizer }} </template>
      </el-table-column>
      <el-table-column label="比赛开始时间" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.start_at }} </template>
      </el-table-column>
      <el-table-column label="比赛结束时间" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.end_at }} </template>
      </el-table-column>
      <el-table-column label="报名队伍数量" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.team_num }} </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.is_on">正常</el-tag>
          <el-tag type="danger" v-else>已下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleSchedule(scope.row)"
            >赛程安排</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handleViewRanking(scope.row)"
            >查看排行</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handleEditMatch(scope.row)"
            >修改赛事</el-button
          >
          <el-button size="mini" type="primary" @click="handleApply(scope.row)">
            查看报名
          </el-button>
          <el-button size="mini" type="primary" @click="handleCancel(scope.row)">
            取消报名管理
          </el-button>
          <el-button
            size="mini"
            type="danger"
            @click="handleStatus(scope.row.id, '下架')"
            v-if="scope.row.is_on"
            >下架</el-button
          >
          <el-button
            size="mini"
            type="success"
            @click="handleStatus(scope.row.id, '上架')"
            v-else
            >上架</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="formRef"
        :rules="formRules"
        :model="form"
        label-width="160px"
        size="small"
      >
        <el-form-item label="赛事封面(420*230)" prop="cover">
          <uploadOne v-model="form.cover"> </uploadOne>
        </el-form-item>
        <el-form-item label="赛事logo(86*86)" prop="logo">
          <uploadOne v-model="form.logo" width="60px" height="60px">
          </uploadOne>
        </el-form-item>
        <el-form-item label="赛事名称" prop="name">
          <el-input type="text" v-model="form.name" />
        </el-form-item>
        <el-form-item label="赛事类型" prop="match_type_id">
          <el-select
            v-model="form.match_type_id"
            placeholder="赛事类型"
            clearable
            style="width: 150px"
            class="filter-item"
          >
            <el-option
              v-for="item in types"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-row>
          <el-col :span="10">
            <el-form-item label="报名开始时间" prop="enroll_start_at">
              <el-date-picker
                v-model="form.enroll_start_at"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="2">
            <el-form-item label="报名结束时间" prop="enroll_end_at">
              <el-date-picker
                v-model="form.enroll_end_at"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                @change="gameTime(form.enroll_start_at, form.enroll_end_at)"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="举办地点" prop="venue">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
            v-model="form.venue"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="举办单位" prop="organizer">
          <!-- <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
            v-model="form.organizer"
          >
          </el-input> -->
          <el-input
            type="text"
            placeholder="请输入举办单位"
            v-model="form.organizer"
          />
        </el-form-item>

        <el-row>
          <el-col :span="10">
            <el-form-item label="比赛开始时间" prop="start_at">
              <el-date-picker
                v-model="form.start_at"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="2">
            <el-form-item label="比赛结束时间" prop="end_at">
              <el-date-picker
                v-model="form.end_at"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                @change="gameTime(form.start_at, form.end_at)"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="奖励" prop="rewards">
          <el-table size="mini" :data="form.rewards" border fit>
            <el-table-column label="名次" align="center" min-width="60">
              <template slot-scope="scope">
                <el-input size="mini" v-model="scope.row.ranking" />
              </template>
            </el-table-column>
            <el-table-column label="奖励" align="center" min-width="60">
              <template slot-scope="scope">
                <el-input size="mini" v-model="scope.row.award" />
              </template>
            </el-table-column>
            <el-table-column
              size="mini"
              label="操作"
              align="center"
              min-width="50"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="handelAttrDel(scope.$index)"
                  >删除</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="handelAttrUp(scope.$index)"
                  >上移</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="handelAttrDown(scope.$index)"
                  >下移</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-button class="button-new-tag" size="small" @click="add()"
            >+ 添加</el-button
          >
        </el-form-item>

        <el-form-item label="经纬度">
          <el-col :md="6">
            <el-input :disabled="true" v-model="form.position"></el-input>
          </el-col>
          <el-col :span="24" style="margin-top: 15px">
            <amap-position
              ref="map"
              @choosePosition="choosePosition"
              :height="300"
              :width="200"
            ></amap-position>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">返回</el-button>
        <el-button @click="saveData" type="primary" :loading="btnLoading"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import uploadOne from "@/components/UploadOne/index.vue";
import AMapPosition from "@/components/AMapPosition";
export default {
  components: {
    uploadOne,
    "amap-position": AMapPosition,
  },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10,
        name: null,
        is_on: null,
        start_at: null,
        end_at: null,
      },
      form: {
        id: "",
        cover: "",
        logo: "",
        name: "",
        match_type_id: "",
        enroll_start_at: "",
        enroll_end_at: "",
        venue: "",
        start_at: "",
        end_at: "",
        position: "",
        rewards: [],
        latitude: "",
        longitude: "",
      },
      types: [],
      listLoading: false,
      list: [],
      total: null,
      dialogFormVisible: false,
      dialogStatus: "",
      btnLoading: false,
      dateArr: null,
      textMap: { create: "添加", update: "编辑" },
      formRules: {
        id: [{ required: true, message: "不能为空", trigger: "change" }],
        cover: [
          { required: true, message: "赛事封面不能为空", trigger: "change" },
        ],
        logo: [{ required: true, message: "logo不能为空", trigger: "change" }],
        name: [
          { required: true, message: "赛事名称不能为空", trigger: "change" },
        ],
        match_type_id: [
          { required: true, message: "赛事类型不能为空", trigger: "change" },
        ],
        enroll_start_at: [
          {
            required: true,
            message: "报名开始时间不能为空",
            trigger: "change",
          },
        ],
        enroll_end_at: [
          {
            required: true,
            message: "报名结束时间不能为空",
            trigger: "change",
          },
        ],
        venue: [
          { required: true, message: "举办地点不能为空", trigger: "change" },
        ],
        organizer: [
          { required: true, message: "举办单位不能为空", trigger: "change" },
        ],
        start_at: [
          {
            required: true,
            message: "比赛开始时间不能为空",
            trigger: "change",
          },
        ],
        end_at: [
          {
            required: true,
            message: "比赛结束时间不能为空",
            trigger: "change",
          },
        ],
        rewards: [
          {
            type: "array",
            required: true,
            message: "比赛奖励不能为空",
            trigger: "change",
          },
        ],
      },
    };
  },
  watch: {
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.start_at = newVal[0];
        this.listQuery.end_at = newVal[1];
      } else {
        this.listQuery.start_at = "";
        this.listQuery.end_at = "";
      }
    },
  },
  created() {
    this.getTypeList();
    this.getList();
  },
  methods: {
    gameTime(sta, end) {
      let staTime = +new Date(sta) / 1000;
      let endTime = +new Date(end) / 1000;
      if (endTime < staTime) {
        // this.$message.error("结束时间不能小于开始时间哦!!");
        return false
      }else{
        return true
      }
    },
    choosePosition(res) {
      console.log(res);
      console.log(res.position.lat);
      console.log(res.position.lng);
      this.form.position = res.position.lat + "," + res.position.lng;
      this.form.longitude = res.position.lng;
      this.form.latitude = res.position.lat;
      this.$forceUpdate(); //强制更新
    },
    getTypeList() {
      request({
        url: "/api/schoolend/match/typeList",
        method: "get",
        params: null,
      }).then((response) => {
        this.types = response.data;
      });
    },
    handleSchedule(row) {
      this.$router.push("/match/competitionarrange?id=" + row.id);
    },
    handleViewRanking(row) {
      this.$router.push(`/match/viewranking?id=` + row.id);
    },
    handleApply(row) {
      this.$router.push('/match/applygame?id=' + row.id);
    },
    handleCancel(row) {
      this.$router.push('/match/cancel?id=' + row.id);
    },
    handelAttrDown(index) {
      if (index >= this.form.rewards.length - 1) {
        return;
      }
      this.form.rewards.splice(
        index + 1,
        0,
        this.form.rewards.splice(index, 1)[0]
      );
    },
    handelAttrUp(index) {
      if (index == 0) {
        return;
      }
      this.form.rewards.splice(
        index - 1,
        0,
        this.form.rewards.splice(index, 1)[0]
      );
    },
    handelAttrDel(index) {
      this.form.rewards.splice(index, 1);
    },
    add() {
      this.form.rewards.push({ ranking: "", award: "" });
    },
    saveData() {
      // if (this.form.rewards.length == 0) {
      //   this.$message({
      //     type: "warning",
      //     message: "请设置奖励",
      //   });
      //   return;
      // }
      let enroll = this.gameTime(this.form.enroll_start_at,this.form.enroll_end_at)
      let game = this.gameTime(this.form.start_at,this.form.end_at)
      if (!enroll) {
        this.$message.error("报名结束时间不能小于开始时间哦!!");
        return
      }
      if (!game) {
        this.$message.error("比赛结束时间不能小于开始时间哦!!");
        return
      }
      if (this.form.rewards.length > 0) {
        for (let index = 0; index < this.form.rewards.length; index++) {
          const element = this.form.rewards[index];
          if (!element.award) {
            this.$message({
              type: "warning",
              message: "请输入奖励",
            });
            return;
          }
          if (element.ranking === "" || element.ranking < 0) {
            this.$message({
              type: "warning",
              message: "请输入名次",
            });
            return;
          }
        }
      }

      this.$refs["formRef"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/schoolend/match/store",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    //上下架
    handleStatus(val, text) {
      this.$confirm("此操作将" + text + "该院系, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/schoolend/match/upDown",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/schoolend/match/list",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleEditMatch(row) {
      this.resetForm();
      this.form = Object.assign({}, row);
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.form.position = this.form.latitude + "," + this.form.longitude;
        this.$refs["formRef"].clearValidate();
        this.$forceUpdate(); //强制更新
      });
    },
    handleCreate() {
      this.resetForm();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["formRef"].clearValidate();
      });
    },
    resetForm() {
      this.form = {
        id: "",
        cover: "",
        logo: "",
        name: "",
        type: "",
        startTime: "",
        endTime: "",
        venue: "",
        start_at: "",
        end_at: "",
        rewards: [],
        latitude: "",
        longitude: "",
      };
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.cell {
  padding: 10px 15px;
  display: flex;
  border: 1px solid #4c4c4c;
}
</style>
